import React, { useEffect } from 'react';
import horizontalLoop from 'utils/functions/gsap/horizontalLoop';
import NordeaBank from './nordea';
import OpBank from './op';
import DanskBank from './dansk';
import PopBank from './pop';
import Omasp from './omasp';
import PankkiBank from './pankki';
import Saastopankki from './saastopankki';
import Handelsbanken from './handelsbanken';
import styles from './oneLine.module.scss';
import AktiaIcon from './aktia';
import AlandsbankenIcon from './alandsbanken';
const HEIGHT = 44;
const WIDTH = 80;
const icons = [<OpBank height={HEIGHT} width={WIDTH} />, <NordeaBank height={HEIGHT} width={WIDTH} />, <DanskBank height={HEIGHT} width={WIDTH} />, <Saastopankki height={HEIGHT} width={WIDTH} />, <PopBank height={HEIGHT} width={WIDTH} />, <Omasp height={HEIGHT} width={WIDTH} />, <PankkiBank height={HEIGHT} width={WIDTH} />, <Handelsbanken height={HEIGHT} width={WIDTH} />, <AktiaIcon height={HEIGHT} width={WIDTH} />, <AlandsbankenIcon height={HEIGHT} width={WIDTH} />];
export default function OneLinePayments() {
  useEffect(() => {
    const animation = horizontalLoop('.gsap-block', {
      repeat: -1,
      paddingRight: 32,
      speed: 0.5
    });
    return () => {
      animation.kill();
    };
  }, []);
  return <div className={styles.wrapper} data-sentry-component="OneLinePayments" data-sentry-source-file="oneLine.tsx">
      <div className={styles.iconBlock}>
        {Array(2).fill(icons).flat().map((icon, index) => <div className="gsap-block" key={index}>{icon}</div>)}
      </div>
    </div>;
}