import React from 'react';
export default function AktiaIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg width={200} height={30} viewBox="0 0 200 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="AktiaIcon" data-sentry-source-file="aktia.tsx">
            <g clipPath="url(#clip0_572_605)" data-sentry-element="g" data-sentry-source-file="aktia.tsx">
                <path fillRule="evenodd" clipRule="evenodd" d="M117.847 29.2902H122.188V9.97176H117.847V29.2902ZM75.0624 17.9791H67.7547L71.4206 8.11471L75.0624 17.9791ZM79.2829 29.2902H83.8894L73.6153 2.56765H69.2741L59 29.2902H63.5582L66.3076 21.8861H76.5335L79.2829 29.2902ZM94.4771 18.3167L102.701 9.97176H97.13L89.5571 17.8824V1H85.2159V29.2902H89.5571V22.8747L91.3418 21.042L97.7329 29.2659H102.991L94.4771 18.3167ZM111.601 29.7244C112.951 29.7244 114.133 29.4588 114.856 29.0247V25.0697C114.254 25.5038 113.241 25.8412 112.372 25.8412C110.925 25.8412 110.057 25.142 110.057 23.5744V13.5655H114.398V9.94789H110.057V4.78647H105.716V9.97176H102.677V13.5894H105.716V24.0806C105.74 27.8912 108.176 29.7244 111.601 29.7244ZM122.719 4.13529C122.719 2.66436 121.465 1.48235 120.018 1.48235C118.571 1.48235 117.316 2.66436 117.316 4.13529C117.316 5.60671 118.571 6.78824 120.018 6.78824C121.465 6.78824 122.719 5.60671 122.719 4.13529ZM136.707 24.1529C136.225 25.3832 134.488 26.1067 132.752 26.1067C130.798 26.1067 128.748 25.2865 128.748 23.3332C128.748 21.4276 130.798 20.5835 132.752 20.5835C134.488 20.5835 136.225 21.3314 136.707 22.5373V24.1529ZM140.831 29.2902V16.6285C140.831 12.2391 137.961 9.53789 133.089 9.53789C129.134 9.53789 126.095 12.0218 125.516 15.3261H129.689C130.171 13.9755 131.256 13.2276 132.993 13.2276C135.477 13.2276 136.683 14.7471 136.683 16.7008V18.2685C135.911 17.7138 133.934 17.0865 132.197 17.0865C127.928 17.0865 124.672 19.6432 124.672 23.3088C124.672 27.2644 127.928 29.6276 131.884 29.6276C134.054 29.6276 136.008 28.8559 136.659 28.1806V29.2659H140.831V29.2902Z" fill="white" data-sentry-element="path" data-sentry-source-file="aktia.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="aktia.tsx">
                <clipPath id="clip0_572_605" data-sentry-element="clipPath" data-sentry-source-file="aktia.tsx">
                    <rect width={82} height="28.9412" fill="white" transform="translate(59 1)" data-sentry-element="rect" data-sentry-source-file="aktia.tsx" />
                </clipPath>
            </defs>
        </svg>;
}