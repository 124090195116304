import React from 'react';
export default function AlandsbankenIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg width={200} height={30} viewBox="0 0 200 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="AlandsbankenIcon" data-sentry-source-file="alandsbanken.tsx">
            <g clipPath="url(#clip0_572_609)" data-sentry-element="g" data-sentry-source-file="alandsbanken.tsx">
                <path d="M19.9494 23.0026V14.1086C18.5375 11.6402 19.8725 13.9837 18.4414 11.5537H24.6941V20.102H30.8123C29.2756 22.9065 30.8123 20.1692 29.218 22.9834H19.9494" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M50.8678 23.0218V14.2046C49.3695 11.6402 50.8198 14.147 49.2734 11.5537H58.1386C61.4235 16.8267 58.2251 11.7266 61.4523 16.8844V11.5537H65.2942V23.0218H59.224C54.8442 16.1352 59.1567 22.8681 54.7866 16.0391V23.0218H50.8678Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M161.772 23.0216C158.89 18.4114 161.724 22.9064 158.89 18.3153V23.0024H154.213V14.0892C152.772 11.6592 154.146 13.9836 152.695 11.5439H158.881V17.787L164.548 11.5632H167.852C163.616 16.2791 167.612 11.8129 163.443 16.4712C167.285 22.8199 163.578 16.7017 167.371 23.0216H161.791" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M170.198 14.0415C168.968 11.9861 170.121 13.8974 168.699 11.5635C173.079 11.5635 177.462 11.5635 181.848 11.5635C183.356 14.0031 181.915 11.6787 183.404 14.1087H175.01V15.9625H178.535V18.4309H175.01V20.5535H183.471C182.252 22.9067 183.385 20.7072 182.194 23.022H170.198V14.0415Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M84.6949 20.102H93.9059C94.045 20.0569 94.1662 19.969 94.2522 19.8508C94.3381 19.7326 94.3844 19.5902 94.3844 19.444C94.3844 19.2979 94.3381 19.1555 94.2522 19.0372C94.1662 18.919 94.045 18.8311 93.9059 18.7861C86.5486 18.8629 84.7814 18.7861 84.6853 15.2996C84.6584 14.4195 84.9464 13.5588 85.4975 12.8721C86.0486 12.1855 86.8266 11.718 87.6916 11.5537H96.6048C96.9122 12.0724 98.0359 13.8877 98.1512 14.0894H90.6403C90.5159 14.1344 90.4084 14.2166 90.3325 14.3249C90.2565 14.4332 90.2158 14.5622 90.2158 14.6945C90.2158 14.8267 90.2565 14.9558 90.3325 15.064C90.4084 15.1723 90.5159 15.2546 90.6403 15.2996H96.9026C100.495 15.8855 100.504 22.5416 96.7777 22.9834H86.5102C84.8198 20.2652 86.4238 22.8489 84.6853 20.102" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M77.9637 11.6396C76.2444 11.5244 75.0822 11.5628 67.3984 11.5628C68.9064 14.0312 67.4753 11.6876 68.9448 14.1081V23.0213C74.4003 23.0213 76.206 23.0213 77.9253 22.9156C79.2622 22.8383 80.5243 22.2736 81.473 21.3285C82.4217 20.3833 82.9911 19.1233 83.0734 17.7867C83.083 14.7804 81.6135 12.2351 77.9637 11.6396ZM73.7664 20.5432V14.1081C76.5998 13.9256 77.9733 14.6363 77.9541 17.681C77.8292 20.015 76.7631 20.7545 73.7664 20.5336V20.5432Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M135.035 23.022C128.312 12.159 134.853 22.7146 127.966 11.5635H119.581C121.176 14.0127 119.697 11.7075 121.205 14.0799L116.508 23.022H120.196C121.253 20.8993 120.446 22.5321 121.445 20.5535H128.264C127.054 22.7626 128.12 20.8033 126.919 23.022H135.035ZM122.972 17.7874C124.345 15.1172 123.058 17.6049 124.403 15.002C125.997 17.3936 124.509 15.1172 126.247 17.7874H122.972Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M18.7293 23.0316C16.3537 19.1897 13.9877 15.367 11.6314 11.5635H3.24641C4.82159 14.0703 3.24641 11.5635 4.80238 14.0703C0 22.9835 4.80238 14.0703 0 23.0124H3.68823C4.87922 20.7744 3.86111 22.7242 5.01369 20.5439H11.8139C10.6037 22.8107 11.7466 20.6592 10.4692 23.022L18.7293 23.0316ZM9.84488 17.7682H6.54084C8.00077 15.1172 6.63689 17.5761 8.12563 14.8867C9.77765 17.5761 9.89291 17.7201 9.84488 17.7682Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M49.3991 23.0312L42.3011 11.5536L33.9066 11.5439C35.3473 13.8587 34.0026 11.688 35.4625 14.07C30.9291 22.551 30.6602 23.012 30.6602 23.0216H34.3676C35.5586 20.6397 34.4348 22.8872 35.6258 20.5532H42.4644C41.2926 22.7623 42.2915 20.851 41.1486 23.0505L49.3991 23.0312ZM37.2202 17.7678C38.7282 14.9825 37.2202 17.691 38.7666 14.9344L40.5339 17.7582L37.2202 17.7678Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M113.566 17.0766V16.9902C114.106 16.7372 114.556 16.3264 114.858 15.8119C115.16 15.2975 115.299 14.7036 115.256 14.1087C115.17 12.4759 114.123 11.7364 112.596 11.5635H100.34L101.886 14.1087V23.0412H113.258C116.841 22.6186 116.409 17.48 113.566 17.0766ZM106.669 14.0895C109.205 14.1472 110.3 13.8494 110.348 14.9059C110.3 16.1449 108.907 15.8184 106.669 15.8664C106.66 15.2997 106.679 14.6658 106.66 14.0895H106.669ZM106.669 20.5439V18.3636C109.656 18.3636 110.78 18.2004 110.867 19.5162C110.742 20.7937 109.589 20.4863 106.65 20.5535L106.669 20.5439Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M5.3994 10.5741C3.90106 8.10565 5.3994 10.5741 3.82422 8H9.38538L11.0278 10.5741H5.3994Z" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M136.673 23.0218V14.2046C135.194 11.6305 136.625 14.1374 135.137 11.5537H143.983C147.268 16.8267 144.069 11.7266 147.306 16.8844V11.5537H151.148V23.0218H144.991C140.602 16.1352 144.914 22.8681 140.544 16.0391V23.0218H136.616" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
                <path d="M185.535 23.0218V14.2046C184.036 11.6305 185.467 14.1374 183.988 11.5537H192.844C196.119 16.8267 192.921 11.7266 196.158 16.8844V11.5537H199.999V23.0218H193.843C189.463 16.1352 193.776 22.8681 189.396 16.0391V23.0218H185.487" fill="white" data-sentry-element="path" data-sentry-source-file="alandsbanken.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="alandsbanken.tsx">
                <clipPath id="clip0_572_609" data-sentry-element="clipPath" data-sentry-source-file="alandsbanken.tsx">
                    <rect width={200} height="15.0507" fill="white" transform="translate(0 8)" data-sentry-element="rect" data-sentry-source-file="alandsbanken.tsx" />
                </clipPath>
            </defs>
        </svg>;
}