import React from 'react';
export default function PankkiBank(props: React.SVGProps<SVGSVGElement>) {
  return <svg className="img-fluid" id="outputsvg" xmlns="http://www.w3.org/2000/svg" width={1600} height={560} viewBox="0 0 16000 5600" {...props} data-sentry-element="svg" data-sentry-component="PankkiBank" data-sentry-source-file="pankki.tsx">
            <g id="lCBA4HYo4nToSRaEfzRiLQ" fill="white" fillOpacity="0.6" style={{
      transform: 'none'
    }} data-sentry-element="g" data-sentry-source-file="pankki.tsx">
                <g style={{
        transform: 'none'
      }} data-sentry-element="g" data-sentry-source-file="pankki.tsx">
                    <path id="p1HfLAvg5J" d="M0 5417 l0 -174 303 -6 c202 -3 335 -10 402 -21 451 -71 781 -237 1080 -540 275 -279 431 -587 511 -1011 15 -77 18 -191 24 -780 6 -663 7 -693 28 -768 48 -175 170 -332 317 -409 88 -46 221 -85 320 -94 l75 -7 0 -225 0 -225 -107 7 c-250 17 -438 77 -614 197 -81 55 -188 157 -250 238 -68 90 -153 266 -185 386 l-28 100 -7 685 c-7 711 -10 771 -54 941 -66 255 -212 501 -402 679 -194 181 -437 303 -720 361 -93 19 -149 23 -400 27 l-293 4 0 -560 0 -559 155 -7 c85 -3 189 -13 232 -21 189 -36 288 -132 313 -302 5 -38 10 -320 10 -636 0 -323 4 -610 10 -665 62 -570 277 -1023 666 -1403 344 -335 776 -537 1288 -604 69 -9 246 -18 413 -22 l293 -6 0 181 0 182 -242 0 c-278 0 -412 10 -584 45 -390 79 -709 254 -980 538 -258 271 -418 595 -490 997 -15 81 -18 198 -24 785 -7 771 -4 737 -82 894 -49 102 -151 209 -243 258 -84 44 -210 81 -321 95 l-94 12 0 223 0 223 99 0 c237 0 470 -78 658 -219 259 -195 404 -470 433 -818 5 -69 10 -378 10 -686 0 -616 4 -677 61 -870 67 -226 184 -422 354 -593 224 -226 488 -355 830 -410 61 -9 200 -18 358 -21 l257 -6 0 565 0 565 -157 6 c-310 11 -444 67 -512 211 -35 76 -41 177 -41 783 0 606 -5 689 -57 923 -172 785 -754 1411 -1521 1634 -256 75 -360 88 -754 93 l-338 5 0 -175z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                    <path id="p1HZ04WRsx" d="M4520 2799 l0 -1630 588 4 c565 3 590 4 671 25 129 34 233 93 321 181 41 42 92 105 113 141 49 84 92 222 106 335 15 121 15 1050 0 1170 -37 298 -177 523 -393 632 -132 66 -189 76 -493 81 l-273 4 0 344 0 344 -320 0 -320 0 0 -1631z m1003 330 c47 -13 102 -60 127 -110 11 -20 24 -69 30 -107 16 -98 8 -933 -8 -983 -19 -58 -54 -102 -102 -129 -43 -24 -52 -25 -225 -25 l-180 0 -3 683 -2 682 162 0 c90 0 180 -5 201 -11z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                    <path id="p1ALB44Lcr" d="M6310 4425 c0 -8 661 -3219 666 -3238 5 -16 33 -17 447 -15 l442 3 327 1610 c180 886 327 1618 327 1628 1 16 -21 17 -322 17 l-324 0 -58 -335 -59 -335 -342 0 c-318 0 -343 1 -348 18 -3 9 -26 136 -51 282 -25 146 -50 289 -56 318 l-10 52 -320 0 c-175 0 -319 -2 -319 -5z m1340 -1250 c0 -15 -212 -1252 -226 -1318 -3 -16 -10 -25 -14 -20 -8 8 -240 1299 -240 1336 0 16 18 17 240 17 210 0 240 -2 240 -15z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                    <path id="p1CsOUkRGe" d="M8730 2800 l0 -1630 327 2 327 3 162 495 c89 272 231 703 314 958 83 254 154 462 158 462 4 0 5 -432 4 -960 l-4 -960 311 0 311 0 0 1630 0 1630 -309 0 -309 0 -50 -147 c-247 -736 -616 -1821 -623 -1833 -5 -8 -9 417 -9 983 l0 997 -305 0 -305 0 0 -1630z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                    <path id="poEaCAqMF" d="M10980 2800 l0 -1630 315 0 315 0 0 953 c0 532 4 945 9 937 5 -8 152 -437 327 -953 l318 -937 327 0 328 0 -28 83 c-16 45 -147 422 -291 837 -238 685 -261 757 -251 785 6 16 143 373 305 793 l294 762 -328 0 -327 0 -128 -337 c-254 -668 -280 -735 -290 -732 -5 2 -67 136 -137 298 l-128 295 0 238 0 238 -315 0 -315 0 0 -1630z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                    <path id="p14sKJ7Oeh" d="M13150 2800 l0 -1630 315 0 315 0 2 950 3 951 294 -868 c162 -477 307 -905 324 -950 l29 -83 325 0 c316 0 325 1 320 19 -2 11 -131 384 -286 829 -154 445 -281 817 -281 827 0 10 135 368 300 796 165 428 300 781 300 784 0 3 -147 4 -326 3 l-327 -3 -203 -535 c-111 -294 -206 -536 -211 -537 -4 -1 -65 132 -135 297 l-128 299 0 240 0 241 -315 0 -315 0 0 -1630z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                    <path id="pjUlJ7IJv" d="M15320 2795 l0 -1625 340 0 340 0 0 1625 0 1625 -340 0 -340 0 0 -1625z" data-sentry-element="path" data-sentry-source-file="pankki.tsx" />
                </g>
            </g>
        </svg>;
}