import React from 'react';
export default function Omasp(props: React.SVGProps<SVGSVGElement>) {
  return <svg width={129} height={48} viewBox="0 0 129 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="Omasp" data-sentry-source-file="omasp.tsx">
            <g clipPath="url(#clip0_43_242)" data-sentry-element="g" data-sentry-source-file="omasp.tsx">
                <mask id="mask0_43_242" style={{
        maskType: 'luminance'
      }} maskUnits="userSpaceOnUse" x={0} y={0} width={129} height={48} data-sentry-element="mask" data-sentry-source-file="omasp.tsx">
                    <path d="M0 0H129V48H0V0Z" fill="white" data-sentry-element="path" data-sentry-source-file="omasp.tsx" />
                </mask>
                <g mask="url(#mask0_43_242)" data-sentry-element="g" data-sentry-source-file="omasp.tsx">
                    <path d="M49.1583 17.3862C52.1272 17.3862 53.9438 19.1826 53.9438 22.3928V36.0944H57.2466V22.251C57.2466 17.6697 54.5182 14.6934 49.5424 14.6934C45.6652 14.6934 43.4143 16.5358 42.1685 19.0409C41.1633 16.5854 38.5319 14.6934 35.0388 14.6934C32.2601 14.6934 29.9625 15.6855 28.3362 18.2366V15.2603H25.0801V36.0944H28.3829V22.9597C28.3829 19.8913 30.7308 17.3862 34.6546 17.3862C37.6703 17.3862 39.4904 19.1826 39.4904 22.3928V36.0944H42.7932V23.1971C42.7932 19.5582 45.378 17.3862 49.1583 17.3862Z" fill="white" fillOpacity="0.6" data-sentry-element="path" data-sentry-source-file="omasp.tsx" />
                    <path d="M115.025 10.6757H118.231C118.231 10.0592 118.231 9.58795 118.087 8.83325C117.035 3.1641 112.343 0 107.173 0C101.142 0 96.306 4.53532 96.306 10.6757C96.306 17.337 100.374 19.7464 100.374 25.9824C100.374 30.5178 97.5015 33.9653 92.9996 33.9653C88.4977 33.9653 85.3888 30.1847 85.3888 25.699C85.3888 21.2133 88.2608 18.2334 91.4702 17.7161C91.7574 17.67 92.2816 17.6204 92.5688 17.6204V14.8815C92.2349 14.8815 91.7072 14.9276 91.2764 14.9772C86.9217 15.498 82.0859 19.2291 82.0859 25.7025C82.0859 31.7472 86.5878 36.6617 92.9529 36.6617C99.318 36.6617 103.77 31.8429 103.77 25.9364C103.77 19.0873 99.7022 16.5823 99.7022 10.6793C99.7022 5.71876 102.959 2.69285 107.217 2.69285C110.95 2.69285 113.966 4.72311 114.878 9.26198C114.924 9.68716 115.021 10.2541 115.021 10.6793" fill="white" fillOpacity="0.6" data-sentry-element="path" data-sentry-source-file="omasp.tsx" />
                    <path d="M118.23 14.6934C114.927 14.6934 112.296 16.348 110.619 19.1826V15.2603H107.316V47.9996H110.619V24.8978C110.619 20.1747 113.922 17.3862 117.943 17.3862C122.585 17.3862 125.651 20.9294 125.651 25.3691C125.651 30.2375 122.825 33.9685 117.943 33.9685C116.554 33.9685 115.405 33.7311 114.4 33.3059V36.0944C115.455 36.4239 116.794 36.6613 117.943 36.6613C124.836 36.6613 129 31.7008 129 25.3691C129 19.5121 124.358 14.6934 118.23 14.6934Z" fill="white" fillOpacity="0.6" data-sentry-element="path" data-sentry-source-file="omasp.tsx" />
                    <path d="M69.8865 14.6934C65.6287 14.6934 62.3726 16.6315 61.6546 19.9373H64.9574C65.6287 18.0488 67.4955 17.3862 69.8865 17.3862C72.7118 17.3862 75.0561 18.5661 75.0561 21.7337V22.3007C75.0561 23.4345 74.4817 24.1431 72.999 24.1431H68.0699C63.7619 24.1431 60.793 26.4569 60.793 30.2835C60.793 34.1102 63.9988 36.6613 68.4038 36.6613C71.1322 36.6613 73.6703 35.6232 75.1063 33.2138V36.0944H78.3625V21.7798C78.3625 17.4819 75.1063 14.6934 69.8901 14.6934H69.8865ZM75.0561 28.5367C75.0561 32.3138 72.0907 34.0641 68.9315 34.0641C65.3882 34.0641 64.1424 32.0799 64.1424 30.2835C64.1424 28.1115 65.4348 26.5986 68.5474 26.5986H72.3779C73.4765 26.5986 74.5319 26.4108 75.0561 25.7943V28.5332V28.5367Z" fill="white" fillOpacity="0.6" data-sentry-element="path" data-sentry-source-file="omasp.tsx" />
                    <path d="M10.8167 14.694C4.78549 14.694 0 19.2754 0 25.6992C0 32.1231 4.78549 36.6584 10.8167 36.6584C16.8479 36.6584 21.6837 32.1231 21.6837 25.6992C21.6837 19.2754 16.8982 14.6904 10.8167 14.6904V14.694ZM10.8167 33.9691C6.31843 33.9691 3.34948 30.4259 3.34948 25.7028C3.34948 20.9796 6.31843 17.3868 10.8167 17.3868C15.315 17.3868 18.3306 20.9761 18.3306 25.7028C18.3306 30.4294 15.3617 33.9691 10.8167 33.9691Z" fill="white" fillOpacity="0.6" data-sentry-element="path" data-sentry-source-file="omasp.tsx" />
                </g>
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="omasp.tsx">
                <clipPath id="clip0_43_242" data-sentry-element="clipPath" data-sentry-source-file="omasp.tsx">
                    <rect width={129} height={48} fill="white" data-sentry-element="rect" data-sentry-source-file="omasp.tsx" />
                </clipPath>
            </defs>
        </svg>;
}